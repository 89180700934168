@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~antd/dist/antd.less';

button:focus {
  outline: none;
}

.bg-azul {
  background-color: #cbecfe !important;
}

p,
ul {
  // margin-bottom: unset !important;
}

.ant-pro-sider-light {
  background-color: #cbecfe !important;
}
.ant-pro-global-header-layout-mix .anticon {
  color: black !important;
  @apply hover:text-gray-700;
  @apply cursor-pointer;
}

// @azul: #ceedff;

// .ant-pro-sider {
//   background-color: @azul!important;
// }

// .ant-pro-global-header {
//   background: @azul!important;
// }

// .ant-menu-dark .ant-menu-item,
// .ant-menu-dark .ant-menu-item-group-title,
// .ant-menu-dark .ant-menu-item > a,
// .ant-menu-dark .ant-menu-item > span > a {
//   color: black !important;
// }

// .ant-menu.ant-menu-dark,
// .ant-menu-dark .ant-menu-sub,
// .ant-menu.ant-menu-dark .ant-menu-sub {
//   color: lighten(black, 10%) !important;
// }
// .ant-menu-dark .ant-menu-inline.ant-menu-sub {
//   background: darken(@azul, 10%) !important;
// }

/* .table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
} */

.ant-pro-global-header-logo a img {
  height: auto !important;
}

.ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex;
  align-items: center;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  padding: 0px !important;
  top: auto !important;
}

.ant-table.ant-table-small .ant-table-cell {
  padding: 6px 8px !important;
}

svg {
  vertical-align: baseline !important;
}

.no-padding-collapse .ant-collapse-content-box {
  padding: 0 !important;
}

@media only screen and (max-width: 580px) {
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  /* .ant-picker-panels > *:last-child {
    display: none;
  } */

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
}

@layout-sider-background: #cbecfe;@layout-sider-background-light: #cbecfe;@layout-header-background: #cbecfe;